.page-container {
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
}

.custom_link {
    text-decoration: underline;
    color: blue;
}

.sectionheader {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

h1 {
    color: var(--Gray-900, #101828);
    font-family: Inter, sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.75rem;
    margin-top: 0;
}

.p1 {
    display: block;
    overflow: hidden;
    color: var(--Gray-600, #475467);
    text-overflow: ellipsis;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

.clear-button {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    font-size: 1rem;
    outline: none;
    margin-left: 10px;
}

.next-step, .mistake {
    display: inline-flex;
    justify-content: center; 
    align-items: center;
    padding: 0 5px;
    height: 18px;
    border-radius: 20px;
    font-size: 10px;
    font-weight: bold;
    width: 50px;
}

.next-step {
    color: #008000;
    background-color: #ccffcc;
    border: 1px solid #008000;
}

.mistake {
    color: #ff0000;
    background-color: #ffcccc;
    border: 1px solid #ff0000;
}

@media (max-width: 800px) {
    .sectionheader, h1, .p1, .clear-button {
        font-size: 0.9rem;
    }

    .DataGrid-container {
        min-width: 600px;
        overflow-x: auto;
    }
}

@media (max-width: 600px) {
    h1, .p1, .clear-button {
        font-size: 0.85rem;
    }

    .DataGrid-container {
        min-width: 500px;
    }
}

@media (max-width: 400px) {
    .sectionheader {
        flex-direction: column;
        align-items: flex-start;
    }

    h1, .p1, .clear-button {
        font-size: 0.65rem;
    }

    .DataGrid-container {
        min-width: 320px;
    }
}