.container {
    width: 100vw;
    margin: 0;
    padding: 0;
    border: none;
    margin-bottom: 20px;
}

.line {
    width: 65%;
    height: 2px;
    background-color: #ccc; /* Gray line */
    position: relative; /* Change to relative */
    margin-bottom: 20px; /* Add margin bottom to give space below the line */
}

.headerSection {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 200px;
    margin-top: 30px;
}

.headerSection h2 {
    margin: 0;
    color: #1F2937;
    font-size: 16px;
    flex-shrink: 0; /* Prevent the title from shrinking */
    white-space: nowrap;
}

.section {
    display: flex;
    align-items: center; 
    margin-bottom: 10px; /* Reduced margin */
}

.label {
    font-size: 16px;
    color: #1F2937;
    font-weight: 600;
    margin-bottom: 5px;
}

.labelThird {
    font-size: 12px;
    color: #1F2937;
    font-weight: 600;
    margin-right: -15px; /* Space between label and input */
    width: 70px; /* Set a fixed width for alignment */
    text-align: center; /* Center text horizontally */
    border: 1px solid #E5E7EB; /* Gray border */
    padding: 8px; /* Padding inside the label */
    border-radius: 6px; /* Rounded corners */
    background-color: #F9FAFB; /* Background color */
    display: flex; /* Use flexbox */
    align-items: center; /* Center text vertically */
    justify-content: center; /* Center text horizontally */
}

.formSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start (left) */
    gap: -10px; /* Space between each section */
    width: 100%;
}

.inputStyleOne {
    flex: 1; /* Allow input to take the remaining space */
    min-width: 246px; /* Set a minimum width to make inputs longer */
    padding: 10px;
    font-size: 13px;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    background-color: #F9FAFB;
    color: #4B5563;
    margin-left: -2px;
}

.inputStyleOne.readonly {
    background-color: #F9FAFB;
    cursor: not-allowed;
}

.inputStyle {
    flex: 1; /* Allow input to take the remaining space */
    min-width: 450px; /* Set a minimum width to make inputs longer */
    padding: 10px;
    font-size: 13px;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    background-color: #F9FAFB;
    color: #4B5563;
    margin-left: 20px;
}

.inputStyle.readonly {
    background-color: #F9FAFB;
    cursor: not-allowed;
}

.inputPair {
    display: flex;
    gap: 20px;
    margin-bottom: 10px; /* Reduced margin */
    margin-left: -10px;
}

.inputContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.inputContainer .label {
    margin-bottom: 5px;
}

/* Media Queries for responsive design */
@media (max-width: 1200px) {
    .headerSection {
        gap: 150px;
    }
    .inputStyle {
        min-width: 400px;
    }
    .inputStyleOne {
        min-width: 200px;
    }
}

@media (max-width: 992px) {
    .headerSection {
        gap: 100px;
    }
    .inputStyle {
        min-width: 350px;
    }
    .inputStyleOne {
        min-width: 150px;
    }
}

@media (max-width: 768px) {
    .headerSection {
        flex-direction: column;
        align-items: flex-start;
    }
    .inputPair {
        flex-direction: column;
        margin-left: 0;
    }
    .inputStyle {
        min-width: 100%;
        margin-left: 0;
    }
    .inputStyleOne {
        min-width: 100%;
        margin-left: 0;
    }
}

@media (max-width: 576px) {
    .headerSection {
        gap: 50px;
    }
    .inputStyle {
        min-width: 100%;
        margin-left: 0;
    }
    .inputStyleOne {
        min-width: 100%;
        margin-left: 0;
    }
    .labelSecond {
        width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
    }
}