html, body {
    height: auto;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.logo-section {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 10px;
}

.logo {
    width: 35vw;
    max-width: 350px;
    height: auto;
    margin-bottom: 0px;
}

.search-form {
    display: flex;
    justify-content: center;
    width: 100%;
}

.search-select {
    flex-grow: 1;
    padding: 10px;
    border: none;
    font-size: 16px;
    outline: none;
}
.search-input {
    width: 18vw;
    flex-grow: 1;
    padding: 10px;
    border: none;
    font-size: 16px;
    outline: none;
}

.search-button {
    width: 13vw;
    max-width: 153px;
    min-width: 100px; 
    height: auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    border: 2px solid var(--Gradient-skeuemorphic-gradient-border, rgba(255, 255, 255, 0.12));
    background: #3C3F96;
    box-shadow: 0px 0px 0px 1px rgba(16, 24, 40, 0.18) inset, 0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
}

.search-text {
    display: flex;
    padding: 0px 2px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    line-height: 24px;
}

.table {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    height: 100vh;
    overflow-x: auto;
}

p {
    margin-top: 5px;
    font-size: 1.2rem;
    color: #666;
}

.input-group {
    margin-right: 10px;
    display: flex;
    border: 2px solid #ccc;
    border-radius: 5px;
    width: auto;
}

@media (max-width: 800px) {
    .logo {
        width: 50vw;
    }

    .search-button, .search-input, .search-select {
        width: 100%;
        font-size: 14px;
        padding: 8px;
    }

    .search-form {
        flex-direction: column;
    }

    .input-group {
        flex-direction: column;
    }
}