.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 350px; /* Adjust width as needed */
    margin-top: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.label {
    color: black; /* Matches the color in the image */
    font-size: 20px;
    font-weight: 500;
}

.select {
    width: 100%;
}

.buttonGroup {
    display: flex;
    justify-content: space-between;
}


.cancelButton, .confirmButton {

    flex: 1; /* Ensure buttons take equal width */
    margin: 10px; /* Remove any existing margin */
}

.cancelButton {
    border: 1px solid #D1D5DB;
    background: white;
    color: #6B7280;
}

.confirmButton {
    background: #6366F1;
    color: white;
    border: none;
}
