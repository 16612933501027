.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4.2rem;
  background: var(--colors-primary, #3F4297);
  padding-left: 2.0rem;
}

.logoBox {
  padding-left: 2.0rem;
}

.header h1 {
  color: var(--Headerwhite, white);
  font-size: 1rem;
}

.centered-img {
  max-width: 100%;
  max-height: 100%;
  width: 170px;
  height: auto;
  margin-bottom: 0px;
}

.right-content {
  display: flex;
  align-items: center;
  padding-right: 6.5rem;
}

.header-button {
  margin-right: 20px; 
  padding: 8px 20px; 
  background-color: #F5853F;
  border: none;
  border-radius: 5px; 
  cursor: pointer;
}

.profile-img {
  width: 35px; /* 图片尺寸 */
  height: 35px;
  border-radius: 50%; /* 圆形图片 */
}

.log-out {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 1;
}