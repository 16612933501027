.orderInformation {
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
    text-align: left;
}

.orderInformation h1 {
    font-size: 24px;
    font-weight: 700;
    color: #1F2937;
    margin: 0;
    padding-bottom: 10px;
}

.orderInformation p {
    font-size: 16px;
    font-weight: 400;
    color: #6B7280;
    margin: 0;
}

.pageContainer {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.content {
    max-width: 800px;
    width: 100%;
}

/* Media Queries for responsive design */
@media (max-width: 1200px) {
    .orderInformation {
        padding: 15px;
    }

    .orderInformation h1 {
        font-size: 22px;
    }

    .orderInformation p {
        font-size: 15px;
    }
}

@media (max-width: 992px) {
    .orderInformation {
        padding: 10px;
    }

    .orderInformation h1 {
        font-size: 20px;
    }

    .orderInformation p {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .orderInformation {
        padding: 5px;
    }

    .orderInformation h1 {
        font-size: 18px;
    }

    .orderInformation p {
        font-size: 13px;
    }
}

@media (max-width: 576px) {
    .orderInformation {
        padding: 5px;
    }

    .orderInformation h1 {
        font-size: 16px;
    }

    .orderInformation p {
        font-size: 12px;
    }
}